import React, { useState, useEffect } from "react";
import Reveal from "react-awesome-reveal";
import OwlCarousel from "../owl-carousel/owl-carousel";
import ProductTwo from "./productTwo";
import { featuredSlider } from "../owl-carousel/data/carousel";
import ALink from "../utils/alink";
import Spinner from "../utils/spinner/full-page-spinner";

import ProductDatabaseService from "../../service/product-database-service";
import { useApiData } from '../../service/api-data-provider';

function ProductGridView(props) {
  const {
    productDatabase,
    productDatabaseLoading,
    productDatabaseIsError,
  } = useApiData();

  const { collectionSlug } = props;
  const [products, setProducts] = useState([]);
  useEffect(() => {
    loadProductDatabase();
  }, [collectionSlug]);

  const loadProductDatabase = () => {
    let products = ProductDatabaseService.getProductsByCollectionSlug(collectionSlug, productDatabase);
    setProducts(products);
  };
  if (productDatabaseLoading) {
    return <Spinner />;
  }
  if (productDatabaseIsError) {
    return <div>Error loading data</div>;
  }
  if (!products || !products.length) {
    return <div>No products found in this collection.</div>;
  }

  return (
    <div className="container">
      <section className="pt-7 pb-1">
        <ALink href="#">
          <h2 className="title title-simple ls-m">
            {products[0]?.collection.collectionName}
          </h2>
        </ALink>

        <Reveal triggerOnce>
          {productDatabaseLoading ? (
            <OwlCarousel
              adClass="owl-theme owl-nav-full"
              options={featuredSlider}
            >
              {[1].map((item) => (
                <div
                  className="product-loading-overlay"
                  key={"featured-skel-" + item}
                ></div>
              ))}
            </OwlCarousel>
          ) : (
            <OwlCarousel
              adClass="owl-theme owl-nav-full"
              options={featuredSlider}
            >
              {products.map((item, index) => (
                <ProductTwo
                  adClass="text-left"
                  product={item}
                  key={`featured-product-${index}`}
                  collectionSlug={item.collection.collectionSlug}
                  collectionData={item.collection}
                />
              ))}
            </OwlCarousel>
          )}
        </Reveal>
      </section>
    </div>
  );
}

export default React.memo(ProductGridView);
