import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';


const BoxSticker = ({ orders = [] }) => {
    return (
        <Document>
            {orders.map((order, orderIdx) => (
                <Page  key={orderIdx}>
                     <Text >{JSON.stringify(order)}</Text>
                </Page>
            ))}
        </Document>
    );
};

export default BoxSticker;