import React, { useEffect, useState } from 'react'
import Spinner from '../../components/utils/spinner/full-page-spinner'
import Papa from 'papaparse';
import DocGenerationService from '../../service/doc-generation-service';
import SupplierReportPdf from './PDF/supplier-report-pdf';
import { pdf } from '@react-pdf/renderer';
import OrderReport from './PDF/order-report';
import axios from 'axios';
import BoxSticker from './PDF/box-stiker';
import { useNavigate } from "react-router-dom";

function DocumentGenerationHome() {
    const navigate = useNavigate();
    const [isFullPageSpinnerActive, setFullPageSpinner] = useState(true);
    const [isOrderButtonPressed, updateOrderButtonStatus] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [error, setError] = useState(null);
    const [rawData, setRawData] = useState(null);
    const [collectionList, setCollectionList] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const url = window.location.href;
            const parts = url.split('?');
            const signedURLDataPath = parts.length > 2 ? `${parts[1]}?${parts[2]}` : parts[1];

            if (signedURLDataPath) {
                try {
                    const response = await axios.get(signedURLDataPath);
                    setRawData(response.data);
                    generateCollectionList(response.data)
                    setFullPageSpinner(false);
                } catch (error) {
                    console.error("Error fetching data from signed URL:", error);
                    setFullPageSpinner(false);
                    navigate('/pages/404')
                }
            }
            else{
                navigate('/pages/404')
            }
        };
        fetchData();
    }, []);

    const generateCollectionList = (rawData) => {
        let collectionListResponse = DocGenerationService.getCollectionListFromRawData(rawData);
        if (collectionListResponse.length > 0) {
            setCollectionList(collectionListResponse)
        }
        else {
            setCollectionList([])
        }
    }
    const generateSupplierReport = async (event) => {
        event.preventDefault();
        setFullPageSpinner(true);
        let fromValues = getFormFieldsValue()
        try {
            let formattedLineItems = await DocGenerationService.getStructuredDataForSupplierReportForSpecificCollection(rawData, fromValues.collectionName)
            let pdfBlob = await pdf(<SupplierReportPdf products={formattedLineItems} />).toBlob();
            const url = URL.createObjectURL(pdfBlob);
            window.open(url);
            setFullPageSpinner(false);
            window.location.reload();
        }
        catch (error) {
            alert('Got Error please try again', error);
        }
    }
    const getStructuredDataAddressStickers = async (event) => {
        event.preventDefault();
        setFullPageSpinner(true);
        try {
            let formattedLineAddressDetails = DocGenerationService.getStructuredDataAddressStickers(rawData)
            let pdfBlob = await pdf(<BoxSticker orders={formattedLineAddressDetails} />).toBlob();
            const url = URL.createObjectURL(pdfBlob);
            window.open(url);
            setFullPageSpinner(false);
            window.location.reload();
        }
        catch (error) {
            alert('Got Error please try again', error);
        }
    }
    const generateOrderReport = () => {
        let orderWithLineItems = DocGenerationService.getStructuredDataForOrderReport(rawData);
        setOrderList(orderWithLineItems);
        updateOrderButtonStatus(true);
    }
    const generateDPDReport = (e) => {
        e.preventDefault();
        let DPDReportData = DocGenerationService.getStructuredDataForDPDReport(rawData);
        downloadJsonAsCsv(DPDReportData , 'PDP Report.csv')
    }
    const downloadJsonAsCsv=(jsonData, filename = 'data.csv')=> {
        if (!Array.isArray(jsonData) || jsonData.length === 0) {
          console.error("Invalid or empty JSON data");
          return;
        }
        const csv = Papa.unparse(jsonData, {
          header: false, 
          quotes: false, 
          delimiter: ',',
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
        setFullPageSpinner(false);
      }
    const getFormFieldsValue = () => {
        const formElement = document.getElementById('supplierForm');
        const personalFormElements = new FormData(formElement);
        return Object.fromEntries(personalFormElements.entries())
    }
    if (isFullPageSpinnerActive) {
        return <Spinner />
    }
    return (
        <>
            {
                isFullPageSpinnerActive ? <Spinner /> : !isOrderButtonPressed ?
                    <div className='mb-4 container'>
                        <h1 className='title hide-on-print'>Generate Report</h1>
                        <div className='mb-4 hide-on-print'></div>
                        {rawData && (
                            <>
                                <form id="supplierForm" className='hide-on-print' >
                                    <select
                                        id='collectionName'
                                        className='form-control'
                                        name='collectionName'
                                        defaultValue=''
                                        required={true}
                                    >
                                        <option value='' disabled>
                                            Available Collections.
                                        </option>
                                        {collectionList.map((item, index) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                    <button className={`btn btn-primary mt-4 mr-2 ${collectionList.length===0 ?'btn-disabled':''}`}  type='submit' onClick={generateSupplierReport} data-action="generateSupplierReport">
                                        Generate Supplier Report For Collection
                                    </button>

                                    <button className={`btn btn-primary mt-4 mr-2 ${collectionList.length===0 ?'btn-disabled':''}`}  data-action="generateOrderReport" onClick={generateOrderReport}>
                                        Generate Order Report
                                    </button>

                                    <button className={`btn btn-primary mt-4 mr-2 ${collectionList.length===0 ?'btn-disabled':''}`}  data-action="generateCustomerMetadata" onClick={getStructuredDataAddressStickers}>
                                        Generate Address Stickers
                                    </button>
                                    <button className={`btn btn-primary mt-4 mr-2 ${collectionList.length===0 ?'btn-disabled':''}`}  data-action="generatePDPExcelFile" onClick={generateDPDReport}>
                                        Generate DPD Excel file
                                    </button>
                                </form>
                            </>
                        )}
                    </div> : null
            }
            {isOrderButtonPressed ?
                <OrderReport orderList={orderList} setFullyLoaded={true} setFullPageSpinner={setFullPageSpinner} /> : null
            }
        </>

    )
}

export default DocumentGenerationHome
