import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './components/store';

////////////////  Import CSS  ///////////////////////////////////////
import './index.css';
import './public/sass/style.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-image-lightbox/style.css';
import './public/vendor/riode-fonts/riode-fonts.css';
/////////////////////////////////////////////////////////////////////

////////////////  Import Components  ///////////////////////////////
import Layout from './components/layout';
import Home from './components/home/home';
import ProductHome from './components/product-view/product-home';
import ProductVariantView from './components/product-variant/product-variant-view';
import Cart from './components/cart/cart';
import Error404 from './pages/Error404';
import Checkout from './components/checkout/checkout';
import ContactUs from './components/contact-us/contact-us';
import Order from './components/order/order';
import { ApiDataProvider } from './service/api-data-provider';
import DocumentGenerationHome from './pages/supplier-report-document/document-generation-home';
import CollectionAll from './components/collection-view/collection-all'
import StoreAll from './components/store-view/store-all';
import ProductGridView from './components/product-view/product-grid-view';
import ProductViewForCollectionOrStore from './components/product-view/product-view';
import DynamicPageGenerator from './pages/dynamic-page-generator';
import ClassicBlog from './components/blogs/classic';
import BlogPost from './components/blogs/single-post/blog-post';
import ComingSoon from './pages/coming-soon';
import DocUploader from './pages/doc-uploader';
import StoreDetailView from './components/store-view/store-product-card-detail-view';
import ProductEntryPage from './components/smart-product/product-entry-page';
import Admin from './components/admin/admin';
import AdminAfterGettingPreSignedURL from './components/admin/admin-after-getting-presignedURL';
/////////////////////////////////////////////////////////////////////

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ApiDataProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Layout children={<Home />} />} />
            
            <Route path="/pages/:pageSlug" element={<Layout children={<DynamicPageGenerator />} />} />
            <Route path="/pages/checkout" element={<Layout children={<Checkout />} />} />
            <Route path="/pages/cart" element={<Layout children={<Cart />} />} />
            <Route path="/pages/contact-us" element={<Layout children={<ContactUs />} />} />
            <Route path="/pages/doc-generation" element={<Layout children={<DocumentGenerationHome />} />} />
            <Route path="/order?/:session_id" element={<Layout children={<Order />} />} />
            <Route path="*" element={<Layout children={<Error404 />} />} />
            <Route path="store/:storeSlug" element={<Layout children={<ProductViewForCollectionOrStore />} />} />
            {/* <Route path="collections/:collection-slug/products/:productSlug" element={<Layout children={<ProductHome />} />} /> */}
            <Route path="store/:storeSlug/products/:productSlug" element={<Layout children={<ProductEntryPage />} />} />
            <Route path="collections/all" element={<Layout children={<CollectionAll />} />} />
            <Route path="store/all" element={<Layout children={<StoreAll />} />} />
            <Route path="blogs/all" element={<Layout children={<ClassicBlog />} />} />
            <Route path="blog/single/:blogSlug" element={<Layout children={<BlogPost />} />} />
            <Route path="pages/comingSoon" element={<Layout children={<ComingSoon />} />} />
            <Route path="pages/doc-upload" element={<Layout children={<DocUploader />} />} />
            <Route path="admin-get-magic-link" element={<Layout children={<Admin />} />} />
            <Route path="admin-after-getting-presignedurl" element={<Layout children={<AdminAfterGettingPreSignedURL />} />} />
          </Routes>
        </Router>
      </ApiDataProvider>
    </Provider>
  </QueryClientProvider>
);

reportWebVitals();
