import React, { useEffect } from 'react';
import ALink from '../../../components/utils/alink';
import { toDecimal } from '../../../components/utils';
function OrderReport(props) {
    const { orderList, setFullyLoaded, setFullPageSpinner } = props;
    // useEffect(() => {
    //     const handlePageLoad = () => {
    //       setFullPageSpinner(false);
    //       setFullyLoaded(true);
    //     };
    //     if (document.readyState === 'complete') {
    //       handlePageLoad();
    //     } else {
    //       window.addEventListener('load', handlePageLoad);
    //     }
    //     return () => {
    //       window.removeEventListener('load', handlePageLoad);
    //     };
    //   }, [setFullyLoaded]);

    return (
        <>
            <div className="main cart border-none">
                <>
                    <div className="page-content">
                        <div className="container mb-2">
                            <>
                                {
                                    orderList.length > 0 ? <>
                                        {orderList.map((singleOrder) => (
                                            <div className='order row page-break'>
                                                <div className="order-results d-flex row">
                                                    <div className="col-lg-2 col-md-3">
                                                        <figure>
                                                            <ALink href=''>
                                                                <img src={process.env.REACT_APP_S3_BUCKET + `/logo.png`}
                                                                    width="100" height="100" alt="product" />
                                                            </ALink>
                                                        </figure>
                                                    </div>
                                                    <div className="col-lg-12 col-md-9 ">
                                                        <p className="product-name text-align-right pr-0">
                                                            {singleOrder.orderId} | {singleOrder.status} | {singleOrder.createdDate} | {singleOrder.email} | {singleOrder.total}
                                                        </p>
                                                        {
                                                            singleOrder.lineItems.map((singleLineItem) => (
                                                                singleLineItem.productId === 'Delivery' ?
                                                                    <div key={singleLineItem.productId} className="main order">
                                                                        <p className="address-detail pb-2 text-align-right">
                                                                            {singleLineItem.customisations.map((entry) => (
                                                                                <strong className='c' key={entry.key}>
                                                                                    <span> </span> {entry.value}
                                                                                </strong>
                                                                            ))}
                                                                        </p>
                                                                    </div>
                                                                    : null
                                                            ))
                                                        }
                                                    </div>
                                                </div>

                                                {/* <h2 className="title title-simple text-left pt-4 font-weight-bold text-uppercase">Order Details</h2> */}
                                                <div className="col-lg-12 col-md-12 pr-lg-4">
                                                    <table className="shop-table cart-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Product</th>
                                                                <th></th>
                                                                <th>Quantity</th>
                                                                <th>Unit Price</th>
                                                                <th>Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {singleOrder.lineItems.map((singleLineItem) =>
                                                                singleLineItem.productId !== 'Delivery' ? (
                                                                    <tr key={"cart" + singleLineItem.productName}>
                                                                        <td className="product-thumbnail">
                                                                            <figure>
                                                                                <ALink
                                                                                    href={`/collections/${singleLineItem.collectionId}/products/${singleLineItem.productId}`}>
                                                                                    <img src={singleLineItem.product_image}
                                                                                        width="100" height="100" alt="product" />
                                                                                </ALink>
                                                                            </figure>
                                                                        </td>
                                                                        <td>
                                                                            <div className="product-name-section">
                                                                                <ALink
                                                                                    href={`/collections/${singleLineItem.collectionId}/products/${singleLineItem.productId}`}>
                                                                                    <p className="mb-2 font-weight-bolder">{singleLineItem.productName}</p>
                                                                                </ALink>
                                                                                {!singleLineItem.isBundledProduct && singleLineItem.customisations.map(
                                                                                    (customisation, index) =>

                                                                                        <p className="mt-0 mb-0 overflow-ellipse">
                                                                                            {customisation.key}:{" "}
                                                                                            <span className="font-weight-normal">{customisation.value}</span>
                                                                                        </p>
                                                                                )}
                                                                                {singleLineItem.isBundledProduct && (
                                                                                    <>
                                                                                        {singleLineItem.bundleProductItems.map((singleBundleItem) => (
                                                                                            <div key={singleBundleItem.productId}>
                                                                                                <p className="mb-2 font-weight-bolder">{singleBundleItem.productName}</p>
                                                                                                {singleBundleItem.customisations.map((customisation, index) =>

                                                                                                    <p
                                                                                                        key={`${singleBundleItem.productId}-${index}-${customisation.key}`}
                                                                                                        className="mt-0 mb-0 overflow-ellipse"
                                                                                                    >
                                                                                                        {customisation.key}:{" "}
                                                                                                        <span className="font-weight-normal">{customisation.value}</span>
                                                                                                    </p>
                                                                                                )}
                                                                                            </div>
                                                                                        ))}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                        <td className="product-subtotal">
                                                                            {singleLineItem.quantity}
                                                                        </td>
                                                                        <td className="product-quantity">
                                                                            {toDecimal(singleLineItem.unit_amount / 100)}
                                                                        </td>
                                                                        <td className="product-price">
                                                                            <span className="amount">
                                                                                &euro;{toDecimal((singleLineItem.unit_amount * singleLineItem.quantity) / 100)}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                ) : ''
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ))}
                                    </> : null
                                }
                            </>
                        </div>
                    </div>
                </>
            </div>
        </>
    );
}

export default OrderReport;