import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useApiData } from '../../service/api-data-provider';
import Spinner from '../utils/spinner/full-page-spinner';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

function AdminAfterGettingPreSignedURL() {
    const {
        tenantConfiguration,
        tenantConfigurationLoading,
        tenantConfigurationIsError,
    } = useApiData();
    const [pageSpinner, setPageSpinner] = useState(false);
    const [magicUrl, setMagicUrl] = useState(false);
    const [queryResponse, setQueryResponse] = useState(null);
    useEffect(() => {
        let url = window.location.href;
        let magicURl = url.split('magicUrl=')[1];
        setMagicUrl(magicURl)
    })
    const handleFormSubmit = async (e) => {
        setQueryResponse(null)
        e.preventDefault()
        setPageSpinner(true);
        let tenantID = process.env.REACT_APP_TENANT_ID;
        let formDetails = getFormDetails('adminForm')
        try {
            let response = await axios.post(
                tenantConfiguration.REACT_APP_PIPELINE_PROD_URL,
                {
                    pipelineName: tenantConfiguration.REACT_APP_RUN_REPORT_QUERY,
                    pipelineParams: [
                        { name: 'email', value: formDetails.email },
                        { name: 'preSignedURL', value: magicUrl},
                        { name: 'query', value:formDetails.query},
                        { name: 'tablePathName', value:formDetails.tableName},
                        { name: 'greaterThanOrEqualToDate', value: formDetails.greaterThanOrEqualToDate },
                        { name: 'lessThanOrEqualToDate', value:formDetails.lessThanOrEqualToDate },
                    ],
                },
                {
                    headers: {
                        'x-tenant-id': tenantID,
                    },
                }
            )
            if (response.status === 200) {
                toast.dismiss();
                toast.success("Success", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,

                });
                setQueryResponse(response.data.message);
            }
        }
        catch (e) {
            toast.dismiss();
            toast.error("Failed to run query or magic link expire", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,

            });
        }
        finally {
            setPageSpinner(false);
        }

    }
    const getFormDetails = (formId) => {
        const formElement = document.getElementById(formId);
        if (!formElement) {
            console.error(`Form with ID ${formId} not found.`);
            return {};
        }
        const formInstance = new FormData(formElement);
        return Object.fromEntries(formInstance.entries())
    }
    if (tenantConfigurationLoading ||pageSpinner) {
        return <Spinner />;
    }
    if (tenantConfigurationIsError) {
        return <p>Error loading tenant configuration.</p>;
    }
    return (
        <div>
            <Helmet>
                <title>{tenantConfiguration.REACT_APP_COMPANY_NAME} | Admin</title>
            </Helmet>
            <div className="container mt-7 page-content pt-2 pb-1 checkout mb-2">
                <div className="row mb-4">
                    <div className="">
                        <h3 className="mb-3 title title-simple text-left text-uppercase text-align-center" >Admin Page</h3>
                        <form className="form form w-100 " id="adminForm" aria-label="Checkout Form" onSubmit={handleFormSubmit}>
                            <div className="d-flex flex-direction-row justify-content-space-evenly" style={{ justifyContent: 'space-around' }}>
                                <div className="form-group">
                                    <label htmlFor="preSignedURL">Email *</label>
                                    <input
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        type="email"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="query">Get ALASQL Statement *</label>
                                    <input
                                        className="form-control"
                                        name="query"
                                        id="query"
                                        type="text"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tableName">Table Name *</label>
                                    <input
                                        className="form-control"
                                        name="tableName"
                                        id="tableName"
                                        type="text"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="greaterThanOrEqualToDate">Greater Than Or Equal *</label>
                                    <input
                                        className="form-control"
                                        name="greaterThanOrEqualToDate"
                                        id="greaterThanOrEqualToDate"
                                        type="date"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lessThanOrEqualToDate">Lesser Than Or Equal *</label>
                                    <input
                                        className="form-control"
                                        name="lessThanOrEqualToDate"
                                        id="lessThanOrEqualToDate"
                                        type="date"
                                        required
                                    />
                                </div>
                            </div>
                            <button
                                type='submit'
                                className="btn btn-dark btn-rounded btn-order mt-3"
                            >
                                Run Report
                            </button>
                        </form>
                    </div>
                </div>
               {
                queryResponse?JSON.stringify(queryResponse):null
               } 
            </div>
            <ToastContainer />
        </div>
    )
}

export default AdminAfterGettingPreSignedURL
