import React, { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


const DataContext = createContext();

export const fetchProductDatabase = async () => {
    try {
        const response = await axios.get(
            process.env.REACT_APP_S3_BUCKET + '/productDatabase.json'
        );
        return response.data;
        
    } catch (error) {
        console.error("Error fetching product database:", error);
        throw new Error('Failed to fetch product database.');
    }
};

export const fetchTenantConfiguration = async () => {
    try {
        const response = await axios.get(
            process.env.REACT_APP_S3_BUCKET + '/tenant-configuration.json'
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching tenant configuration:", error);
        throw new Error('Failed to fetch tenant configuration.');
    }
};
const fetchHomePageComponentsMetadata = async () => {
    let response = await axios.get(
        process.env.REACT_APP_S3_BUCKET + "/home-page-configuration-metadata.json"
    );

    if (response.status === 200) {
        return response.data;
    }
};
const fetchHeaderTabsMetaData = async () => {
    let response = await axios.get(
        process.env.REACT_APP_S3_BUCKET + '/header-tabs-configurations-metadata.json'
    );
    if (response.status === 200) {
        return response.data;
    }
};
const fetchDynamicPagesMetadataIndex = async () => {
    let response = await axios.get(
        process.env.REACT_APP_S3_BUCKET + '/dynamic-pages-metadata-index.json'
    );
    if (response.status === 200) {
        return response.data;
    }
};
const fetchBlogPostMetadata = async () => {
    let response = await axios.get(
        process.env.REACT_APP_S3_BUCKET + '/blog-database.json'
    );
    if (response.status === 200) {
        return response.data;
    }
};
const fetchStoreMetadata = async () => {
    let response = await axios.get(
        process.env.REACT_APP_S3_BUCKET + '/storeDatabase.json'
    );
    if (response.status === 200) {
        return response.data;
    }
};
export const ApiDataProvider = ({ children }) => {
    if (!children) {
        throw new Error("ApiDataProvider must wrap components and provide 'children' as its prop.");
    }

    const productDatabaseQuery = useQuery({
        queryKey: ['productDatabase'],
        queryFn: fetchProductDatabase,
        staleTime: 5 * 60 * 1000,
    });
    const tenantConfigurationQuery = useQuery({
        queryKey: ['fetchTenantConfiguration'],
        queryFn: fetchTenantConfiguration,
        staleTime: 5 * 60 * 1000,
    });
    const getHomePageComponentsMetadataQuery = useQuery({
        queryKey: ['fetchHomePageComponentsMetadata'],
        queryFn: fetchHomePageComponentsMetadata,
        staleTime: 5 * 60 * 1000,
    });
    
    const getHeaderTabsMetaDataQuery = useQuery({
        queryKey: ['fetchHeaderTabsMetaData'],
        queryFn: fetchHeaderTabsMetaData,
        staleTime: 5 * 60 * 1000,
    });
    const getFetchDynamicPagesMetadataIndexQuery = useQuery({
        queryKey: ['fetchDynamicPagesMetadataIndex'],
        queryFn: fetchDynamicPagesMetadataIndex,
        staleTime: 5 * 60 * 1000,
    });
    const getBlogPostMetadataQuery = useQuery({
        queryKey: ['fetchBlogPostMetadata'],
        queryFn: fetchBlogPostMetadata,
        staleTime: 5 * 60 * 1000,
    });
    const getStoreQuery = useQuery({
        queryKey: ['fetchStoreMetadata'],
        queryFn: fetchStoreMetadata,
        staleTime: 5 * 60 * 1000,
    });

    return (
        <DataContext.Provider
            value={{
                productDatabase: productDatabaseQuery.data,
                productDatabaseLoading: productDatabaseQuery.isLoading,
                productDatabaseIsError: productDatabaseQuery.error,

                tenantConfiguration: tenantConfigurationQuery.data,
                tenantConfigurationLoading: tenantConfigurationQuery.isLoading,
                tenantConfigurationIsError: tenantConfigurationQuery.isError,
                
                homePageComponentsConfiguration: getHomePageComponentsMetadataQuery.data,
                homePageComponentsConfigurationLoading: getHomePageComponentsMetadataQuery.isLoading,
                homePageComponentsConfigurationIsError: getHomePageComponentsMetadataQuery.isError,
                
                headerTabsMetaDataConfiguration: getHeaderTabsMetaDataQuery.data,
                headerTabsMetaDataConfigurationLoading: getHeaderTabsMetaDataQuery.isLoading,
                headerTabsMetaDataConfigurationIsError: getHeaderTabsMetaDataQuery.isError,
                
                dynamicPagesMetadataIndex: getFetchDynamicPagesMetadataIndexQuery.data,
                dynamicPagesMetadataIndexLoading: getFetchDynamicPagesMetadataIndexQuery.isLoading,
                dynamicPagesMetadataIndexIsError: getFetchDynamicPagesMetadataIndexQuery.isError,

                blogPostMetadata: getBlogPostMetadataQuery.data,
                blogPostMetadataLoading: getBlogPostMetadataQuery.isLoading,
                blogPostMetadataIsError: getBlogPostMetadataQuery.isError,
                
                storeDatabase: getStoreQuery.data,
                storeDatabaseLoading: getStoreQuery.isLoading,
                storeDatabaseIsError: getStoreQuery.isError,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

export const useApiData = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useApiData must be used within an ApiDataProvider');
    }
    return context;
};
